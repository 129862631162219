import { graphql } from "gatsby"
import React from "react"
import ThreeLinkedImageBoxes from "./ThreeLinkedImageBoxes"

export const fragment = graphql`
  fragment ThreeLinkedImageBoxesFragment on WpPage_Flexlayouts_FlexibleLayouts_ThreeLinkedImageBoxes {
    sectionHeading
    text
    images {
      link {
        title
        url
        target       
      }
      image {
        altText
        sourceUrl
        localFile {
          childImageSharp {
            gatsbyImageData(
              formats: [AUTO, WEBP, AVIF]
              width: 700
              placeholder: NONE
            )
          }
        }
      }
    }
    config {
      padding {
        top {
          topMobile
          topTablet
          topDesktop
        }
        bottom {
          bottomMobile
          bottomTablet
          bottomDesktop
        }
      }
    }
  }
`
export const ACFThreeLinkedImageBoxes = ({
  sectionHeading,
  text,
  images,
  config,
}) => {
  return (
    <ThreeLinkedImageBoxes
      sectionHeading={sectionHeading}
      text={text}
      images={images}
      config={config}
    />
  )
}
