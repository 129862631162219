import { GatsbyImage, getImage } from "gatsby-plugin-image"
import React from "react"
import { Row, Col, Container } from "react-bootstrap"
import { Section, SectionHeading, TextBlock, LinkButton } from "../../UI/Common"
import { imgCls, buttonCls } from "./ThreeLinkedImageBoxes.module.scss"

const ThreeLinkedImageBoxes = ({
  sectionHeading,
  text,
  images,
  config,
}) => {

  const { padding } = config || {}
 
  return (
    <Section padding={padding}>
      <Container>
        <SectionHeading          
          text={sectionHeading}
          alignment="center"
        />
        {text && <TextBlock text={text} alignment="center" mode="muted" />}
        <Row>
          {React.Children.toArray(images.map(item => {
            const { link, image } = item
            return (
              <Col xs={12} lg={4} className="my-3 my-lg-0">
                <div
                  className={`
                    d-flex 
                    justify-content-center
                    align-items-center
                    position-relative               
                `}>
                  <GatsbyImage
                    className={imgCls}
                    alt={image.altText}
                    image={getImage(image.localFile)}
                  />
                  <LinkButton
                    className={buttonCls}
                    title={link.title}
                    link={link.url}
                    target={link.target}
                    variant="secondary"
                  />
                </div>
              </Col>
            )
          }))}
        </Row>
      </Container>
    </Section>
  )
}

export default ThreeLinkedImageBoxes
